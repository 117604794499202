window.addEventListener("load", function () {
    if (document.querySelectorAll('.s-blog__menu-list').length > 0) {
        var sticky = document.querySelector('.s-blog__menu-list');
        var footer = document.querySelector('.contact-us');
        var origOffsetY = sticky.offsetTop;
        var footerOffsetY = footer.offsetTop;
        document.addEventListener('scroll', function (e) {
            if (window.scrollY + sticky.offsetHeight >= footerOffsetY) {
                sticky.classList.add('fixed-bottom');
            } else {
                sticky.classList.remove('fixed-bottom');
            }
        });
    }
});

jQuery(document).ready(function ($) {
    let scrolledBlock = $('.s-blog__menu-list');

    if (scrolledBlock.length > 0) {
        function fixDiv() {
            let $div = $(".s-blog__menu-list");
            if ($(window).scrollTop() > $div.data("top")) {
                $('.s-blog__menu-list').addClass('fixed-top');
            } else {
                $('.s-blog__menu-list').removeClass('fixed-top');
            }
        }

        $(".s-blog__menu-list").data("top", $(".s-blog__menu-list").offset().top); // set original position on load
        $(window).scroll(fixDiv);

        $('a[href*="#"]:not([href="#"])').on('click', function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });

        var lastId,
            topMenu = $(".list-article"),
            select = 'active',
            topMenuHeight = topMenu.outerHeight(),
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) {
                    return item;
                }
            });


// Bind to scroll
        $(window).scroll(function () {

            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var curID = scrollItems.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            curID = curID[curID.length - 1];

            var id = curID && curID.length ? curID[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .parent().removeClass(select)
                    .end().filter('[href="#' + id + '"]').parent().addClass(select);
            }
        });
    }

});

