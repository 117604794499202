jQuery(document).ready(function ($) {

    $(document).mouseup(function (e) {
        var popup = $('.js-dropdown');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            popup.removeClass('active');
        }
    });

    $('.js-dropdown').on('click', function () {
        $(this).toggleClass('active');
    });

    $('.o-lang-dropdown a').on('click', function () {
        $('.o-lang-dropdown a').removeClass('active')
        $(this).addClass('active');

        const lang = $(this).text();
        $('.o-lang-active span').empty().html(lang);
    });

    $('.js-navi-toggle').on('click', function () {
        $(this).toggleClass('active');
        $('.navi').toggleClass('active');
        $('body').toggleClass('navi-active');
    });

    $(window).on('load resize', function () {
        if ($(window).innerWidth() >= 992) {
            $('body').removeClass('navi-active');
        }
    });

    $('.fly-labels .input').focus(function () {
        $(this).parent().addClass('focused');
    });

    $('.fly-labels .input').blur(function () {
        if (!$(this).val()) {
            $(this).parent().removeClass('focused');
        }
    });

    $('.input-file').change(function () {
        if ($(this).val() != '') $(this).prev().html('<span>Выбрано: ' + $(this)[0].files[0].name + ' </span>');

        else $(this).prev().html('<span>Прикрепить файл</span>');
    });

    $('.js-scroll-top').on('click', function (e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: 0
        }, 1500);

        return false;
    });

    $('.js-show-popup').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        modal: true,
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            },
            open: function () {
                $('html').css('overflow', 'hidden').css('margin-right', '0');
            },
            close: function () {
                $('html').css('overflow', 'auto').css('margin-right', '0');
            }
        },
    });

    $(document).on('click', '.o-popup-close', function () {
        $.magnificPopup.close();
    });

    function closePopup() {
        $("form")[0].reset();
        $.magnificPopup.close();
    }

});
