// const { default: Swiper } = require("swiper");
jQuery(document).ready(function ($) {

  var breakpoint = window.matchMedia('(min-width: 767px)');
  var casesSlider;

  var breakpointChecker = function () {
    if (breakpoint.matches === true) {
      if (casesSlider !== undefined) {
        if ($('.o-cswiper').length) {
          $('.o-cswiper').removeClass('swiper-container');
          $('.o-cswiper__item').unwrap('.swiper-wrapper');
          $('.o-cswiper__item').removeClass('swiper-slide');
          $('.o-cswiper .swiper-pagination').remove();
          casesSlider.destroy(true, true);
        }
      }
      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  };

  var enableSwiper = function () {
    $('.o-cswiper').addClass('swiper-container');
    if (!$('.o-cswiper .swiper-wrapper').length) {
      $('.o-cswiper__slide').wrapAll('<div class="swiper-wrapper"></div>');
    }
    $('.o-cswiper__slide').addClass('swiper-slide');

    casesSlider = new Swiper('.js-cases-slider', {
      slidesPerView: 'auto',
      spaceBetween: 20,
    });
  }

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();

  if ($('.js-case-swiper').length > 0) {
    caseSwiper = new Swiper('.js-case-swiper', {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
    });
  }

});